import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { 
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Container,
  Paper,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
  },
  paper: {
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  goBack: {
    marginTop: theme.spacing(2),
  }
}));

const PasswordReset = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

const handleSendVerificationCode = async () => {
    if (!email) {
      toast.error("Por favor, introduce tu email");
      return;
    }

    setLoading(true);
    
    // Array de rutas a probar
    const routesToTry = [
      "/api/enviar-email",
      "/enviar-email",
      "/api/password/enviar-email",
      "/password/enviar-email",
      "/auth/enviar-email"
    ];

    for (const route of routesToTry) {
      try {
        console.log(`Intentando ruta: ${route}`);
        await api.post(route, { email });
        console.log(`Éxito con ruta: ${route}`);
        toast.success("Se ha enviado un código de verificación a tu email");
        setStep(2);
        break;
      } catch (error) {
        console.error(`Error con ruta ${route}:`, error.response?.status);
        console.error("Respuesta completa:", error.response?.data);
        
        // Solo mostrar el error toast si es la última ruta
        if (route === routesToTry[routesToTry.length - 1]) {
          toastError(error.response?.data?.error || "No se pudo enviar el código de verificación");
        }
      }
    }
    setLoading(false);
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      toast.error("Por favor, introduce el código de verificación");
      return;
    }

    setLoading(true);
    try {
      // Ruta corregida
      const response = await api.get(`/verificar-code/${email}`);
      const storedCode = response.data.code;
      
      if (verificationCode === storedCode) {
        toast.success("Código verificado correctamente");
        setStep(3);
      } else {
        toastError("Código de verificación incorrecto");
      }
    } catch (error) {
      console.error("Error al verificar código:", error);
      const errorMessage = error.response?.data?.error || "Error al verificar el código";
      toastError(errorMessage);
      if (errorMessage.includes("expirado")) {
        setTimeout(() => setStep(1), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!newPassword) {
      toast.error("Por favor, introduce la nueva contraseña");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    if (newPassword.length < 8) {
      toast.error("La contraseña debe tener al menos 8 caracteres");
      return;
    }

    setLoading(true);
    try {
      // Ruta corregida
      await api.put("/atualizar-senha", {
        email,
        code: verificationCode,
        newPassword
      });
      
      toast.success("Contraseña actualizada exitosamente");
      setTimeout(() => history.push("/login"), 1500);
    } catch (error) {
      console.error("Error al resetear contraseña:", error);
      const errorMessage = error.response?.data?.error || "Error al actualizar la contraseña";
      toastError(errorMessage);
      if (errorMessage.includes("expirado")) {
        setTimeout(() => setStep(1), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setLoading(true);
    try {
      await api.post("/api/password/request-reset", { email });
      toast.success("Se ha enviado un nuevo código de verificación");
    } catch (error) {
      console.error("Error al reenviar código:", error);
      toastError(error.response?.data?.error || "No se pudo reenviar el código");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} className={classes.paper}>
          <Typography component="h1" variant="h5" gutterBottom>
            Recuperación de Contraseña
          </Typography>

          <form className={classes.form} noValidate>
            {step === 1 && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSendVerificationCode}
                  disabled={loading || !email}
                >
                  {loading ? "Enviando..." : "Enviar Código de Verificación"}
                </Button>
              </>
            )}

            {step === 2 && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Código de Verificación"
                  name="code"
                  autoFocus
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  disabled={loading}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleVerifyCode}
                  disabled={loading || !verificationCode}
                >
                  {loading ? "Verificando..." : "Verificar Código"}
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  onClick={handleResendCode}
                  disabled={loading}
                >
                  Reenviar código
                </Button>
              </>
            )}

            {step === 3 && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Nueva Contraseña"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar Contraseña"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={loading}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleResetPassword}
                  disabled={loading || !newPassword || !confirmPassword}
                >
                  {loading ? "Actualizando..." : "Actualizar Contraseña"}
                </Button>
              </>
            )}

            <Button
              fullWidth
              color="secondary"
              className={classes.goBack}
              onClick={() => history.push("/login")}
              disabled={loading}
            >
              Volver al Login
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default PasswordReset;