import React, { useState, useEffect } from "react";
import qs from "query-string";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { InputLabel, MenuItem, Select, FormControl } from "@material-ui/core";
import usePlans from "../../hooks/usePlans";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import logo from "../../assets/logo.png";
import "./style.css";

const ContactPlanCard = () => (
  <div className="plan-card contact-plan">
    <div className="plan-title">¿Necesitas un plan personalizado?</div>
    <div className="plan-detail">
      Contacta con nosotros para crear un plan que se adapte a tus necesidades específicas
    </div>
    <div className="plan-detail">
      Soluciones personalizadas para grandes empresas
    </div>
    <a 
      href="https://www.cloudanddelivery.es/contacto" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="contact-button"
    >
      Contactar
    </a>
  </div>
);

const PlanSelector = ({ plans, i18n, formik }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (planId) => {
    if (selectedPlan === planId) {
      setSelectedPlan(null);
      formik.setFieldValue('planId', '');
    } else {
      setSelectedPlan(planId);
      formik.setFieldValue('planId', planId);
    }
  };

  const sortedPlans = [...plans].sort((a, b) => a.amount - b.amount);

  return (
    <div className="plan-selector-container">
      <label className="plan-title">
        <center>Escoge el Plan más adecuado</center>
      </label>
      <div className="plans-grid">
        {sortedPlans.map((plan) => (
          <div
            key={plan.id}
            onClick={() => handlePlanSelect(plan.id)}
            className={`plan-card ${selectedPlan === plan.id ? 'selected' : ''}`}
          >
            <div className="plan-title">{plan.name}</div>
            <div className="plan-detail">
              {i18n.t("signup.form.attendants")}: {plan.users}
            </div>
            <div className="plan-detail">
              {i18n.t("signup.form.whatsapp")}: {plan.connections}
            </div>
            <div className="plan-detail">
              {i18n.t("signup.form.queues")}: {plan.queues}
            </div>
            <div className="plan-price">
              {plan.amount} € al mes
            </div>
          </div>
        ))}
        <ContactPlanCard />
      </div>
      <Field type="hidden" name="planId" />
    </div>
  );
};

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("signup.errors.tooShort"))
    .max(50, i18n.t("signup.errors.tooLong"))
    .required(i18n.t("signup.errors.required")),
  companyName: Yup.string()
    .min(2, i18n.t("signup.errors.tooShort"))
    .max(50, i18n.t("signup.errors.tooLong"))
    .required(i18n.t("signup.errors.required")),
  password: Yup.string()
    .min(5, i18n.t("signup.errors.tooShort"))
    .max(50, i18n.t("signup.errors.tooLong")),
  email: Yup.string()
    .email(i18n.t("signup.errors.invalidEmail"))
    .required(i18n.t("signup.errors.required")),
  phone: Yup.string().required(i18n.t("signup.errors.required")),
});

const SignUp = () => {
  const history = useHistory();
  const { getPlanList } = usePlans();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  let companyId = null;
  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = { name: "", email: "", password: "", phone: "", companyId, companyName: "", planId: "" };

  const [user] = useState(initialState);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const planList = await getPlanList();
      setPlans(planList);
      setLoading(false);
    };
    fetchData();
  }, []);

const handleSignUp = async (values) => {
  try {
    // First create the user in the database
    const response = await openApi.post("/auth/signup", values);

    const selectedPlan = plans.find(plan => plan.id === values.planId);
    
    if (selectedPlan) {
      switch (selectedPlan.name) {
        case "Plan Básico":
          window.location.href = `https://buy.stripe.com/fZe6r4er6fv7fPW4gj?prefilled_email=${encodeURIComponent(values.email)}`;
          break;
        case "Plan Medio":
          window.location.href = `https://buy.stripe.com/6oE6r4ciY2IlavC6os?prefilled_email=${encodeURIComponent(values.email)}`;
          break;
        case "Plan Avanzado":
          window.location.href = `https://buy.stripe.com/eVadTw6YE4Qt33afZ3?prefilled_email=${encodeURIComponent(values.email)}`;
          break;
        default:
          // Show message for other plans
          toast.info("El equipo comercial le contactará al email indicado.");
          return;
      }
    }
    
    // Show success message before redirect
    toast.success(i18n.t("signup.toasts.success"));
  } catch (err) {
    toastError(err);
  }
};

  return (
    <div className="root">
      <Formik
        initialValues={user}
        enableReinitialize={true}
        validationSchema={UserSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSignUp(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {(formikProps) => (
          <>
            <div className="container-signup">
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <img src={logo} alt="Whats" className="logo" />
                <div className="header-text">
                  <h4 className="h4">⚡ Regístrate</h4>
                  <div>
                    <span className="span">
                      👋🏻 Comienza tu <b>prueba GRATUITA</b> de 10 días del CRM Cloud And Delivery!
                    </span>
                    <span className="span">
                      <br /><br />Se incluye el uso de CRM WhatsApp, pero ciertos elementos como los flujos avanzados (DialogFlow, Typebot...) solo están incluídas en sus versiones gratuitas y habrán de contratarse a parte para flujos altamente demandantes.
                    </span>
                  </div>
                </div>
                <Form className="form">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="companyName"
                        label={i18n.t("signup.form.companyName")}
                        name="companyName"
                        error={formikProps.touched.companyName && Boolean(formikProps.errors.companyName)}
                        helperText={formikProps.touched.companyName && formikProps.errors.companyName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="name"
                        label={i18n.t("signup.form.name")}
                        name="name"
                        error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                        helperText={formikProps.touched.name && formikProps.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label={i18n.t("signup.form.email")}
                        name="email"
                        error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                        helperText={formikProps.touched.email && formikProps.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        name="password"
                        label={i18n.t("signup.form.password")}
                        type="password"
                        id="password"
                        error={formikProps.touched.password && Boolean(formikProps.errors.password)}
                        helperText={formikProps.touched.password && formikProps.errors.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="phone"
                        label={i18n.t("signup.form.phone")}
                        name="phone"
                        error={formikProps.touched.phone && Boolean(formikProps.errors.phone)}
                        helperText={formikProps.touched.phone && formikProps.errors.phone}
                      />
                    </Grid>
                  </Grid>

                  {/* Mobile plans section */}
                  <div className="mobile-plans-section">
                    <PlanSelector
                      plans={plans}
                      i18n={i18n}
                      formik={formikProps}
                    />
                  </div>

                  <Button type="submit" fullWidth variant="contained" className="submit">
                    {i18n.t("signup.buttons.submit")}
                  </Button>

                  <Grid container>
                    <Grid item>
                      <Link
                        href="#"
                        variant="body1"
                        component={RouterLink}
                        to="/login"
                        className="link-login"
                      >
                        {i18n.t("signup.buttons.login")}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
                <div className="footer">
                  <p className="p">
                    Copyright ©{" "}
                    <a href={"#"} target={"_blank"}>
                      CloudAndDelivery.es{" "}
                    </a>{" "}
                    2024{" "}
                  </p>
                  <p className="p">
                    Este sitio está protegido por reCAPTCHA Enterprise y la política de privacidad de CloudAndDelivery 
                    <a href={"https://www.cloudanddelivery.es/declaracion-de-privacidad/"} target={"_blank"}>
                      Política de Privacidad
                    </a> 
                    y 
                    <a href={"https://www.cloudanddelivery.es/aviso-legal/"} target={"_blank"}>
                      Términos del Servicio
                    </a>
                  </p>
                </div>
                <Box mt={5}></Box>
              </Container>
            </div>
            <div className="container-img-signup">
              <PlanSelector
                plans={plans}
                i18n={i18n}
                formik={formikProps}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;