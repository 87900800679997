import React, { useContext, useState, useEffect } from "react";

// Material-UI core imports
import {
  Paper,
  Container,
  Grid,
  Typography,
  AppBar,
  Button,
  Tab,
  Tabs
} from "@material-ui/core";

// Material-UI icons
import {
  Call as CallIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  GroupAdd as GroupAddIcon,
  HourglassEmpty as HourglassEmptyIcon,
  CheckCircle as CheckCircleIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  Send as SendIcon,
  Message as MessageIcon,
  AccessAlarm as AccessAlarmIcon,
  Timer as TimerIcon
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { isArray, isEmpty } from "lodash";
import moment from "moment";

// Custom components and hooks
import TabPanel from "../../components/TabPanel";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { AuthContext } from "../../context/Auth/AuthContext";
import useDashboard from "../../hooks/useDashboard";
import useContacts from "../../hooks/useContacts";
import useMessages from "../../hooks/useMessages";
import { ChatsUser } from "./ChartsUser";
import ChartDonut from "./ChartDonut";
import Filters from "./Filters";
import { ChartsDate } from "./ChartsDate";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "1150px",
    minWidth: "xs",
  },
  card1: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#0094bb",
    color: "#eee",
  },
  card2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#748e9d",
    color: "#eee",
  },
  card3: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#7f78e6",
    color: "#eee",
  },
  card4: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#cc991b",
    color: "#eee",
  },
  card5: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#434243",
    color: "#eee",
  },
  card6: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#b87d77",
    color: "#eee",
  },
  card7: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#7bc780",
    color: "#eee",
  },
  card8: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#b05c38",
    color: "#eee",
  },
  card9: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#bd3c58",
    color: "#eee",
  },
  cardContainer: {
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[10],
    },
  },
  cardIcon: {
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'rotate(10deg) scale(1.1)',
    },
  },
  actionButton: {
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      transform: 'scaleX(0)',
      transition: 'transform 0.3s ease',
    },
    '&:hover': {
      transform: 'translateY(-2px)',
      '&::after': {
        transform: 'scaleX(1)',
      },
    },
  },
  enhancedGrid: {
    opacity: 0,
    animation: '$fadeIn 0.5s ease-out forwards',
    '&.MuiGrid-root.MuiGrid-item': {
      transition: 'all 0.3s ease-in-out',
    },
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  fixedHeightPaper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("Indicadores");
  const [showFilter, setShowFilter] = useState(false);
  
  // Date handling
  const now = moment().format("YYYY-MM-DD");
  const [dateStartTicket, setDateStartTicket] = useState(now);
  const [dateEndTicket, setDateEndTicket] = useState(now);
  const [queueTicket, setQueueTicket] = useState(false);
  const [fetchDataFilter, setFetchDataFilter] = useState(false);

  const { user } = useContext(AuthContext);
  const { find } = useDashboard();

  useEffect(() => {
    const firstLoad = async () => {
      await fetchData();
    };
    
    const timer = setTimeout(() => {
      firstLoad();
    }, 1000);

    return () => clearTimeout(timer);
  }, [fetchDataFilter]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let params = {};
      
      if (!isEmpty(dateStartTicket) && moment(dateStartTicket).isValid()) {
        params.date_from = moment(dateStartTicket).format("YYYY-MM-DD");
      }

      if (!isEmpty(dateEndTicket) && moment(dateEndTicket).isValid()) {
        params.date_to = moment(dateEndTicket).format("YYYY-MM-DD");
      }

      if (Object.keys(params).length === 0) {
        toast.error("Parametrize o filtro");
        setLoading(false);
        return;
      }

      const data = await find(params);
      setCounters(data.counters || {});
      setAttendants(isArray(data.attendants) ? data.attendants : []);
    } catch (err) {
      toast.error("Erro ao carregar dados");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const formatTime = (minutes) => {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  };

  const GetUsers = () => {
    let userOnline = attendants.filter(user => user.online === true).length;
    return userOnline || 0;
  };

  const GetContacts = (all) => {
    let props = all ? {} : {
      dateStart: dateStartTicket,
      dateEnd: dateEndTicket,
    };
    const { count } = useContacts(props);
    return count;
  };

  const GetMessages = (all, fromMe) => {
    let props = {
      fromMe,
      ...(all ? {} : {
        dateStart: dateStartTicket,
        dateEnd: dateEndTicket,
      })
    };
    const { count } = useMessages(props);
    return count;
  };

  const toggleShowFilter = () => setShowFilter(!showFilter);

return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} className={classes.container}>
          {/* Filtros */}
          <Grid item xs={12}>
            <Button
              className={classes.actionButton}
              onClick={toggleShowFilter}
              style={{ float: "right" }}
              color="primary"
            >
              {!showFilter ? <FilterListIcon /> : <ClearIcon />}
            </Button>
          </Grid>

          {showFilter && (
            <Filters
              classes={classes}
              setDateStartTicket={setDateStartTicket}
              setDateEndTicket={setDateEndTicket}
              dateStartTicket={dateStartTicket}
              dateEndTicket={dateEndTicket}
              setQueueTicket={setQueueTicket}
              queueTicket={queueTicket}
              fetchData={setFetchDataFilter}
            />
          )}

          {/* Tabs */}
          <AppBar position="static">
            <Grid container width="100%" >
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="primary tabs example"
                variant="fullWidth"
              >
                <Tab value="Indicadores" label={i18n.t("dashboard.tabs.indicators")} />
                <Tab value="NPS" label={i18n.t("dashboard.tabs.assessments")} />
                <Tab value="Atendentes" label={i18n.t("dashboard.tabs.attendants")} />
              </Tabs>
            </Grid>
          </AppBar>

          {/* Panel de Indicadores */}
          <TabPanel
            className={classes.container}
            value={tab}
            name="Indicadores"
          >
            <Container maxWidth="xl" className={classes.container}>
              <Grid container spacing={3}>
                {/* EM ATENDIMENTO */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card1}
                    style={{ overflow: "hidden" }}
                    elevation={4}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.inAttendance")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {counters.supportHappening || 0}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <CallIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#0b708c",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* AGUARDANDO */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card2}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.waiting")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {counters.supportPending || 0}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <HourglassEmptyIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#47606e",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

{/* ATENDENTES ATIVOS */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card6}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.activeAttendants")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetUsers()}
                            <span style={{ color: "#805753" }}>
                              /{attendants.length}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <RecordVoiceOverIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#805753",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* FINALIZADOS */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card3}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.finalized")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {counters.supportFinished || 0}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <CheckCircleIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#5852ab",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* NOVOS CONTATOS */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card4}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.newContacts")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetContacts(true)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <GroupAddIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#8c6b19",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* MENSAGENS RECEBIDAS */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card5}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.totalReceivedMessages")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetMessages(false, false)}
                            <span style={{ color: "#787878" }}>
                              /{GetMessages(true, false)}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <MessageIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#333133",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

{/* MENSAGENS ENVIADAS */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card7}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.totalSentMessages")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetMessages(false, true)}
                            <span style={{ color: "#558a59" }}>
                              /{GetMessages(true, true)}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <SendIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#558a59",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* T.M. DE ATENDIMENTO */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card8}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.averageServiceTime")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {formatTime(counters.avgSupportTime)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <AccessAlarmIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#7a3f26",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* T.M. DE ESPERA */}
                <Grid item xs={12} sm={6} md={4} className={`${classes.enhancedGrid} ${classes.cardContainer}`}>
                  <Paper
                    className={classes.card9}
                    style={{ overflow: "hidden" }}
                    elevation={6}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("dashboard.cards.averageWaitingTime")}
                        </Typography>
                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {formatTime(counters.avgWaitTime)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <TimerIcon
                          className={classes.cardIcon}
                          style={{
                            fontSize: 100,
                            color: "#8a2c40",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>

          {/* Panel de NPS */}
          <TabPanel
            className={classes.container}
            value={tab}
            name="NPS"
          >
            <Grid className={classes.container}>
              <Grid container width="100%" spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={3}>
                    <ChartDonut
                      data={[
                        `{'name': 'Promotores', 'value': ${counters.npsPromotersPerc || 100}}`,
                        `{'name': 'Detratores', 'value': ${counters.npsDetractorsPerc || 0}}`,
                        `{'name': 'Neutros', 'value': ${counters.npsPassivePerc || 0}}`
                      ]}
                      value={counters.npsScore || 0}
                      title="Score"
                      color={
                        (parseInt(counters.npsPromotersPerc || 0) +
                          parseInt(counters.npsDetractorsPerc || 0) +
                          parseInt(counters.npsPassivePerc || 0)) === 0
                          ? ["#918F94"]
                          : ["#2EA85A", "#F73A2C", "#F7EC2C"]
                      }
                    />
                  </Paper>
                </Grid>

<Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={3}>
                    <ChartDonut
                      title={i18n.t("dashboard.assessments.prosecutors")}
                      value={counters.npsPromotersPerc || 0}
                      data={[`{'name': 'Promotores', 'value': 100}`]}
                      color={["#2EA85A"]}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={3}>
                    <ChartDonut
                      data={[`{'name': 'Neutros', 'value': 100}`]}
                      title={i18n.t("dashboard.assessments.neutral")}
                      value={counters.npsPassivePerc || 0}
                      color={["#F7EC2C"]}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={3}>
                    <ChartDonut
                      data={[`{'name': 'Detratores', 'value': 100}`]}
                      title={i18n.t("dashboard.assessments.detractors")}
                      value={counters.npsDetractorsPerc || 0}
                      color={["#F73A2C"]}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Paper elevation={3}>
                    <Typography
                      component="h3"
                      variant="h6"
                      paragraph
                      style={{ marginLeft: "10px" }}
                    >
                      {i18n.t("dashboard.assessments.totalCalls")}: {counters.tickets} <br />
                      {i18n.t("dashboard.assessments.callsWaitRating")}: {counters.waitRating} <br />
                      {i18n.t("dashboard.assessments.callsWithoutRating")}: {counters.withoutRating} <br />
                      {i18n.t("dashboard.assessments.ratedCalls")}: {counters.withRating} <br />
                      {i18n.t("dashboard.assessments.evaluationIndex")}:{" "}
                      {Number(counters.percRating / 100).toLocaleString(undefined, { style: "percent" })} <br />
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Panel de Atendentes */}
          <TabPanel
            className={classes.container}
            value={tab}
            name="Atendentes"
          >
            <Container width="100%" className={classes.container}>
              <Grid container width="100%">
                {/* INFO DOS USUARIOS */}
                <Grid item xs={12}>
                  {attendants.length ? (
                    <TableAttendantsStatus
                      attendants={attendants}
                      loading={loading}
                    />
                  ) : null}
                </Grid>

                {/* TOTAL DE ATENDIMENTOS POR USUARIO */}
                <Grid item xs={12}>
                  <Paper className={classes.fixedHeightPaper2}>
                    <ChatsUser />
                  </Paper>
                </Grid>

                {/* TOTAL DE ATENDIMENTOS */}
                <Grid item xs={12}>
                  <Paper className={classes.fixedHeightPaper2}>
                    <ChartsDate />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;